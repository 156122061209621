import { useState } from 'react'
import { InputOrDisplayBox } from '../../components'
import { Guarantor, InputMode } from './../../constants'
import './signee.css'

export const Signee = ({
  guarantor,
  onChange,
  isSubmitPressed,
}: {
  guarantor: Guarantor
  onChange?: (guarantorContactId: string, email: string) => void
  isSubmitPressed: boolean
}) => {
  const [email, setEmail] = useState('')

  const onChangeHandler = (changeEvent, guarantorContactId) => {
    const email = changeEvent.target.value
    setEmail(email)
    onChange(guarantorContactId, email)
  }

  const inputMode = guarantor.guarantor_email
    ? InputMode.display
    : InputMode.input
  return (
    <div className="signee-white-block">
      <div className="label typog-body-small">Full Name</div>
      <div className="display-box">{guarantor.guarantor_name}</div>
      <div className="label add-margin typog-body-small">Email</div>
      <InputOrDisplayBox
        mode={inputMode}
        value={guarantor.guarantor_email || email}
        onChange={(e) => onChangeHandler(e, guarantor.guarantor_contactid)}
        isSubmitPressed={isSubmitPressed}
      />
    </div>
  )
}
