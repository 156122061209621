import { capitalize } from '../../utils'
import './radio-button-group.css'

export const RadioButtonGroup = ({
  title,
  name,
  value,
  optionsList,
  symbol,
  handleOnChange,
}: {
  title: string
  value: string
  name: string
  optionsList: Array<string>
  symbol?: string
  handleOnChange: (newValue: string) => void
}) => {
  return (
    <div className="radio-button-group-section-block">
      <div className="radio-block typog-body">
        <div className="radio-button-group-title typog-body-small">{title}</div>
        <div className="radio-group">
          {[...optionsList]?.sort().map((opt) => {
            return (
              <label
                key={`${opt}-label`}
                className="radio-option typog-body"
                htmlFor={`${opt}-value`}
              >
                {optionsList.length > 1 && (
                  <input
                    key={`${opt}-value`}
                    id={`${opt}-value`}
                    name={name}
                    type="radio"
                    value={opt}
                    checked={value == opt}
                    onChange={({ target: { value } }) => handleOnChange(value)}
                  />
                )}
                <div className="value typog-body">
                  {capitalize(opt)}
                  {symbol && symbol}
                </div>
              </label>
            )
          })}
        </div>
      </div>
    </div>
  )
}
