import { useAtom, useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'
import leftChevronIcon from './../../assets/imgs/left-chevron.svg'
import {
  Card,
  ErrorModal,
  InputOrDisplayBox,
  Page,
  SubmitButton,
} from './../../components'
import {
  Guarantor,
  Guarantors,
  GuarantorsPost,
  InputMode,
  PendingOfferStatus,
  POST_HEADERS_JSON,
  type PendingOfferTemp,
} from './../../constants'
import { isPageLoadingState, pendingOfferState } from './../../state'
import { hasOwn, isEmailValid, logColor, scrollToTop } from './../../utils'
import './create-loan-agreement.css'
import { Signee } from './signee'

export const CreateLoanAgreement = () => {
  const setIsLoading = useSetAtom(isPageLoadingState)

  const [pendingOffer, setPendingOffer] = useAtom(pendingOfferState)
  const [guarantorsList, setGuarantorsList] = useState<Guarantor[]>([])
  const [isReviewLoanAgreement, setIsReviewLoanAgreement] =
    useState<boolean>(false)
  const [updatedGuarantorsObj, setUpdatedGuarantorsObj] = useState<Guarantors>(
    {},
  )
  const [isSubmitPressed, setIsSubmitPressed] = useState<boolean>(false)
  const [isDisplayErrorModal, setIsDisplayErrorModal] = useState<boolean>(false)
  const [isoEmail, setIsoEmail] = useState<string>('')
  useEffect(() => {
    scrollToTop()
  }, [])

  // load guarantors from pendingoffer into list
  useEffect(() => {
    if (pendingOffer?.guarantors) {
      const g = pendingOffer.guarantors
      const keys = Object.keys(g)
      const list = keys.map((key) => g[key])
      setGuarantorsList(list)
      const guarantorsObj = {}
      keys.forEach((key) => {
        const g = { ...pendingOffer.guarantors[key] }
        // originally we were only going to send guarantor_email for those that were updated.
        // but the back end is errorring if a guarantor does not have a guarantor_email, so...
        // if (g.guarantor_email) delete g.guarantor_email
        guarantorsObj[key] = g
      })
      setUpdatedGuarantorsObj(guarantorsObj)
    }
  }, [pendingOffer])

  const handleBack = async () => {
    setIsLoading(true)
    const status = PendingOfferStatus[PendingOfferStatus.PROVISIONAL_ACCEPTED]
    const response = await fetch(`/api/partner-portal/calculator/status`, {
      method: 'POST',
      headers: POST_HEADERS_JSON,
      body: JSON.stringify({ status, uuid: pendingOffer.uuid }),
    })
    if (response.ok && Object.keys(pendingOffer).length > 0) {
      // We need a type guarantee that pendingOffer isn't an empty object
      const realPendingOffer = pendingOffer as PendingOfferTemp
      // go back, set status to active, accepted
      setPendingOffer({ ...realPendingOffer, status })
    } else {
      // error response
      logColor.red(
        'ERROR offer status change to provisional_accepted returned an error',
        response,
      )
      setIsDisplayErrorModal(true)
    }
    setIsLoading(false)
  }

  const areEmailsValid = () => {
    const keys = Object.keys(updatedGuarantorsObj)
    const invalidEmails = []
    keys.forEach((key) => {
      const guarantor = updatedGuarantorsObj[key]
      if (!hasOwn(guarantor, 'guarantor_email')) {
        return // do not test email for guarantor that did not require email entry
      }
      const isValidEmail = isEmailValid(guarantor.guarantor_email)
      if (!isValidEmail) {
        invalidEmails.push(guarantor.guarantor_email)
      }
    })

    if (!pendingOffer?.partner_email) {
      const isValidIsoEmail = isEmailValid(isoEmail)
      if (!isoEmail || !isValidIsoEmail) {
        invalidEmails.push(isoEmail)
      }
    }

    logColor.yellow(
      `Invalid email list: (${invalidEmails.length}): ${invalidEmails}`,
    )
    // TODO make invalid emails highlighted when tabbing through form
    return invalidEmails.length === 0
  }

  const handleSubmit = async () => {
    setIsSubmitPressed(true)
    if (!areEmailsValid()) {
      logColor.red('INVALID EMAILS', updatedGuarantorsObj)
      return
    }
    logColor.green('Guarantors', updatedGuarantorsObj)
    logColor.green('isReviewLoanAgreement', isReviewLoanAgreement)
    const postBodyObj: GuarantorsPost = {
      uuid: pendingOffer.uuid,
      iso_loan_agree_review_needed: isReviewLoanAgreement, // review loan agreement checkbox value here
      guarantors: updatedGuarantorsObj,
    }

    if (isoEmail.length > 0) postBodyObj.new_partner_email = isoEmail

    logColor.purple('sending gaurantors to back end', { postBodyObj })
    setIsLoading(true)
    const response = await fetch(`/api/partner-portal/calculator/agreement`, {
      method: 'POST',
      headers: POST_HEADERS_JSON,
      body: JSON.stringify(postBodyObj),
    })
    logColor.gray('response', response)
    if (
      response?.status !== 200 ||
      (hasOwn(response, 'status') &&
        hasOwn(response, 'error') &&
        !hasOwn(response, 'owner'))
    ) {
      // error response
      // TODO if this offer is completed then this would fail
      logColor.red(
        'ERROR offer status change to provisional_accepted returned an error, TODO handle',
        response,
      )
      setIsDisplayErrorModal(true)
    } else {
      // set status to active, accepted
      const solidPendingOffer = pendingOffer as PendingOfferTemp
      const status = PendingOfferStatus[PendingOfferStatus.COMPLETED]
      setPendingOffer({ ...solidPendingOffer, status })
    }

    setIsLoading(false)
  } // END handleSubmit

  const handleReviewAgreementChange = (event) => {
    setIsReviewLoanAgreement(event.target.value === 'yes')
  }

  const handleSigneeEmailUpdate = (
    guarantorContactId: string,
    email: string,
  ) => {
    // update the email in the updatedGuarantorsObject
    setUpdatedGuarantorsObj({
      ...updatedGuarantorsObj,
      [guarantorContactId]: {
        ...updatedGuarantorsObj[guarantorContactId],
        guarantor_email: email,
      },
    })
  }

  const handleIsoEmailChange = (changeEvent) => {
    setIsoEmail(changeEvent.target.value)
  }

  return (
    <>
      <ErrorModal
        isDisplay={isDisplayErrorModal}
        handleClose={() => setIsDisplayErrorModal(false)}
      />
      <Page title={'Offer Submission Calculator'}>
        <div className="go-back-section" onClick={handleBack}>
          <img
            className="go-back-chevron-icon"
            src={leftChevronIcon}
            alt={'go back icon'}
          />
          <div className="go-back-link typog-body"> Back</div>
        </div>
        <Card width="1140px">
          <div className="create-loan-agreement-body-content-wrapper">
            <div className="offer-title typog-h1-extra-large">
              Create Loan Agreement
            </div>
            <div className="offer-text typog-body">
              Please add the details for your DocuSign Loan Agreement below.
            </div>
            <div className="width-750-px">
              <div className="section-title typog-h4-small">
                ISO Contact Email
              </div>
              <div className="shaded-iso-contact-email-block">
                {!pendingOffer?.partner_email && (
                  <div className="missing-email-text typog-body">
                    Please enter an email.
                  </div>
                )}
                <InputOrDisplayBox
                  mode={
                    pendingOffer?.partner_email
                      ? InputMode.display_bold
                      : InputMode.input
                  }
                  value={pendingOffer?.partner_email || isoEmail}
                  onChange={handleIsoEmailChange}
                  isSubmitPressed={isSubmitPressed}
                />
              </div>
              <div className="section-title typog-h4-small">
                Guarantor Information
              </div>
              <div className="shaded-guarantor-block">
                <div className="auth-sig-text typog-body">
                  Authorized Signee
                </div>
                <div className="review-text typog-body-small">
                  Review or enter the email address of the authorized signer
                  below
                </div>
                {guarantorsList.map((guarantor, idx) => (
                  <Signee
                    key={'signee-' + idx}
                    guarantor={guarantor}
                    onChange={handleSigneeEmailUpdate}
                    isSubmitPressed={isSubmitPressed}
                  />
                ))}
              </div>
              <div className="dark-box">
                <div className="dark-box-text typog-body">
                  Would you like to review the loan agreement prior to sending
                  to the merchant?
                </div>
                <div className="radio-row">
                  <input
                    name={'reviewAgreement'}
                    value={'yes'}
                    type={'radio'}
                    checked={isReviewLoanAgreement}
                    onChange={handleReviewAgreementChange}
                  />
                  <label className="radio-label typog-body">Yes</label>
                  <input
                    name={'reviewAgreement'}
                    className="radio-button margin-left"
                    value={'no'}
                    checked={!isReviewLoanAgreement}
                    type={'radio'}
                    onChange={handleReviewAgreementChange}
                  />
                  <label className="radio-label typog-body">No</label>
                </div>
                <SubmitButton onClick={handleSubmit} paddingLR={50} width={298}>
                  Request Loan Agreement
                </SubmitButton>
                <div className="dark-box-fine-print typog-body-small-subtitle">
                  Please note that the ISO sales team at Mulligan Funding will
                  be included in the email correspondence and have access to any
                  loan agreements generated in this manner.
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Page>
    </>
  )
}
