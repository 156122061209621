import { useAtomValue } from 'jotai'
import { ReactNode } from 'react'
import './page.css'
import headerLogo from '../../assets/imgs/MF_Horizontal_RGB.svg'
import { PageLoader } from '../../components'
import { isPageLoadingState } from '../../state'

export const Page = ({
  children,
  title,
  widthPx,
  footerNoteNode,
}: {
  title?: string
  widthPx?: number
  footerNoteNode?: ReactNode | ReactNode[]
  children: ReactNode
}) => {
  const isLoading = useAtomValue(isPageLoadingState)

  return (
    <div className="page-wrapper">
      <div className="header-bar">
        <div className="header-centered" style={{ width: `${widthPx}px` }}>
          <img
            className="header-icon"
            src={headerLogo}
            alt={'Mulligan Funding Logo'}
          />
          {!title ? null : (
            <>
              <div className="header-divider">&nbsp;</div>
              <h1 className="title">{title}</h1>
            </>
          )}
        </div>
      </div>

      <div className="page">
        <div className="body typog-body">
          <PageLoader display={isLoading} />
          {children}
        </div>
        <div className="footer">
          {/* footerNoteNode is for displaying footnotes above standard legalese */}
          {footerNoteNode && (
            <div
              style={{ width: `${widthPx || 1140}px`, marginBottom: '20px' }}
            >
              {footerNoteNode}
            </div>
          )}
          <div className="footerText">
            {`© ${new Date().getFullYear()} Mulligan Funding. All Rights Reserved.`}
          </div>
        </div>
      </div>
    </div>
  )
}
