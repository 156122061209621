import * as React from 'react'
import './tool-tip.css'

export interface ToolTipProps {
  display: boolean
  children?: React.ReactNode
}
export const ToolTip: React.FC<ToolTipProps> = (props) => {
  const { display, children } = props

  return (
    <div className={` ${display ? 'tool-tip-wrapper' : 'tool-tip-hidden'}`}>
      <div className="tool-tip-text typog-body-small">{children}</div>
    </div>
  )
}
