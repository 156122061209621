import { ChangeEventHandler, useMemo } from 'react'
import { PATTERN_ORANGEBAR } from '../../constants'
import './multi-thumb-slider.css'

export const MultiThumbSlider = ({
  min,
  max,
  value,
  step,
  subSliderValue,
  onChange,
}: {
  min: number
  max: number
  value: number
  step: number
  subSliderValue: number
  onChange: ChangeEventHandler<HTMLInputElement>
}) => {
  const mainSliderPercentage = useMemo(() => {
    return Math.trunc((100 * (value - min)) / (max - min))
  }, [min, max, value])

  const subSliderPercentage = useMemo(() => {
    return Math.trunc((100 * (subSliderValue - min)) / (max - min))
  }, [min, max, subSliderValue])

  const mainRangeSliderStyles = useMemo(() => {
    const style = `
      #main-range {
        &::-webkit-slider-runnable-track {
          background: linear-gradient(to right, var(--slider-blue) ${mainSliderPercentage}%, transparent ${mainSliderPercentage}% 100%);
          height: 6px;
          }
        }    
      `
    return style
  }, [mainSliderPercentage])
  const subRangeSliderStyles = useMemo(() => {
    const style = `
          #sub-range {
          &::-webkit-slider-runnable-track {
                background: linear-gradient(
                    to right,
                    transparent ${subSliderPercentage}%,
                    var(--slider-grey) ${subSliderPercentage}% 100%
                  ),
                  url(${PATTERN_ORANGEBAR});
                height: 6px;
              }
                    ::-moz-range-progress {
      background: url(${PATTERN_ORANGEBAR});
      height: 6px;
    }
      `
    return style
  }, [subSliderPercentage])

  return (
    <div className="multi-thumb-slider-container">
      <div className="slider">
        <style>
          {mainRangeSliderStyles} {subRangeSliderStyles}
        </style>
        <input
          id="sub-range"
          className="sub-range-slider"
          type="range"
          min={min}
          max={max}
          value={subSliderValue}
          step={step}
          onChange={() => null}
        />
        <input
          id="main-range"
          className="main-range-slider"
          type="range"
          min={min}
          max={max}
          value={value}
          step={step}
          onChange={onChange}
        />

        <div className="slider-default-track"></div>
      </div>
    </div>
  )
}
