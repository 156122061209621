import './checkbox-button.css'

export const CheckboxButton = ({
  label,
  id,
  name,
  isSelected,
  handleChange,
}: {
  id: string
  label: string
  name: string
  isSelected: boolean
  isLocked: boolean
  handleChange: (newValue: string) => void
}) => {
  return (
    <div
      className={
        isSelected ? 'checkboxButtonContainerActive' : 'checkboxButtonContainer'
      }
      onClick={() => handleChange(id)}
    >
      <label className="checkbox-button-checkmark-label typog-body-small-subtitle">
        {label}
      </label>

      <input
        id={id}
        name={name}
        type="radio"
        value={id}
        checked={isSelected}
        onChange={() => null}
      />
    </div>
  )
}
