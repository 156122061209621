import { type ReactNode } from 'react'
import iconError from '../../assets/imgs/icon-error.svg'
import iconLoanAgreement from '../../assets/imgs/icon-loan-agreement.svg'
import iconOfferLocked from './../../assets/imgs/icon-offer-locked.svg'
import { Card, Page } from './../../components'
import './message-page.css'
import { MessagePageType } from '../../constants'

const MessagePageTemplate = ({
  widthPx,
  title,
  children,
}: {
  widthPx?: number
  icon: string
  title: string
  children: ReactNode
}) => {
  return (
    <Page title={'Offer Submission Calculator'}>
      <Card width={widthPx ? widthPx + 'px' : '1140px'}>
        <div className="message-page-wrapper">
          <img src={iconLoanAgreement} />
          <div className="message-page-title typog-h1-extra-large">{title}</div>
          {children}
        </div>
      </Card>
    </Page>
  )
}

export const MessagePage = ({
  type,
  children,
}: {
  type: MessagePageType
  children?: ReactNode
}) => {
  let title, icon, text
  if (type === MessagePageType.LOAN_AGREEMENT_CREATED) {
    title = 'Loan Agreement Created'
    icon = iconLoanAgreement
    text = (
      <div className="message-page-text typog-body">
        Thank You! The loan agreement has been created. Please check your email
        for further details, and please let us know if you have any questions.
        You can reach our team at{' '}
        <span className="message-page-link">(855) 433-8617</span> or send an
        email to{' '}
        <span className="message-page-link">partner@mulliganfunding.com</span>
      </div>
    )
  }
  if (type === MessagePageType.OFFER_LOCKED) {
    title = 'Offer Locked'
    icon = iconOfferLocked
    text = (
      <div className="message-page-text typog-body">
        The offer you're trying to access is now expired or locked. If you need
        assistance, please contact us at{' '}
        <span className="message-page-link">(855) 433-8617</span> or send an
        email to{' '}
        <span className="message-page-link">partner@mulliganfunding.com</span>.
      </div>
    )
  }
  if (type === MessagePageType.SOMETHING_WENT_WRONG) {
    title = 'Something Went Wrong'
    icon = iconError
    text = (
      <div className="message-page-text typog-body">
        We’re sorry, but the page you are trying to reach is not available.
        Please go back and try again. If this issue persists, please contact us
        at <span className="message-page-link">(855) 433-8617</span> or send an
        email to{' '}
        <span className="message-page-link">partner@mulliganfunding.com</span>.
      </div>
    )
  }

  return (
    <MessagePageTemplate icon={icon} title={title}>
      {text}
      {children}
    </MessagePageTemplate>
  )
}
