import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Loader, SentryApp, SentryComponent } from '../components'
import { About } from '../routes'
import {
  PATH_ABOUT,
  PATH_ABOUT_ROOT,
  PATH_ISO_CALC,
  PATH_ISO_CALC_ROOT,
} from './../constants'
import './app.css'

export const App = () => {
  return (
    <BrowserRouter>
      <SentryApp
        appId={`4504278936125440`}
        customerId={`5796b72d7ceb4dde939490dd1d872456@o423005`}
        subDomain={`partner`}
      >
        <div className="app-background">
          <div className="partner-portal-react-app">
            <Routes>
              <Route
                element={
                  <SentryComponent>
                    <About />
                  </SentryComponent>
                }
                path={PATH_ABOUT}
              />
              <Route
                element={
                  <SentryComponent>
                    <About />
                  </SentryComponent>
                }
                path={PATH_ABOUT_ROOT}
              />
              <Route
                element={
                  <SentryComponent>
                    {/* Load Pending Offer and select component to display */}
                    <Loader />
                  </SentryComponent>
                }
                path={PATH_ISO_CALC}
              />
              <Route
                element={
                  <SentryComponent>
                    {/* Load Pending Offer and select component to display */}
                    <Loader />
                  </SentryComponent>
                }
                path={PATH_ISO_CALC_ROOT}
              />
            </Routes>
          </div>
        </div>
      </SentryApp>
    </BrowserRouter>
  )
}
