import { capitalize } from '../../utils'
import './form-dropdown.css'

export const FormDropdown = ({
  title,
  name,
  value,
  optionsList,
  handleOnChange,
}: {
  title: string
  value: string
  name: string
  optionsList: Array<string>
  handleOnChange: (newValue: string) => void
}) => {
  return (
    <div className="dropdown-section-block">
      <div className="dropdown-block typog-body">
        <div className="dropdown-title typog-body-small">{title}</div>
        <div className="dropdown-container">
          {optionsList.length > 1 ? (
            <select
              name={name}
              id={name}
              onChange={({ target: { value } }) => handleOnChange(value)}
            >
              {[...optionsList]?.map((opt) => {
                return (
                  <option
                    key={`${opt}-value`}
                    id={`${opt}-value`}
                    value={opt}
                    selected={value === opt}
                  >
                    {opt}
                  </option>
                )
              })}
            </select>
          ) : (
            <div className="value typog-body">{capitalize(value)}</div>
          )}
        </div>
      </div>
    </div>
  )
}
