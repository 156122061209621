import { type ReactNode } from 'react'
import './modal.css'
import closeModalIcon from './../../assets/imgs/close-modal-icon.svg'

export const Modal = ({
  closeMethod,
  children,
}: {
  children: ReactNode
  closeMethod: () => void
}) => {
  const handleCloseClick = (e) => {
    e.stopPropagation()
    closeMethod()
  }

  const preventBubbling = (e) => {
    e.stopPropagation()
  }

  return (
    <>
      <div className="modal-overlay" onClick={handleCloseClick}></div>
      <div className="modal-content-wrapper" onClick={preventBubbling}>
        <div className="accent-line"></div>
        <div className="header">
          <img
            src={closeModalIcon}
            className="x-close"
            onClick={handleCloseClick}
          />
        </div>
        {children}
      </div>
    </>
  )
}
