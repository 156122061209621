import React, { type ReactNode } from 'react'
import { capitalize } from './../../utils'
import './item-card.css'

export const ItemCard = ({
  children,
  label,
  valueList,
  subTextLabel,
  subTextValueList,
  displaySubtext,
}: {
  label: string
  valueList?: string[]
  subTextLabel?: string
  subTextValueList?: string[]
  displaySubtext?: boolean
  children?: ReactNode
}) => {
  return (
    <div className="item-card-wrapper">
      <div className={valueList ? 'item-card' : 'item-card-with-children'}>
        <div className={valueList ? 'label' : 'label-with-children'}>
          {label}
        </div>
        <div className="value-block">
          <div key={'spacer'} className="spacer" />
          {valueList
            ? valueList.map((v, index) => {
                return (
                  <React.Fragment key={`fragment-${index}`}>
                    {index === 0 ? null : (
                      <div
                        key={`v-sep-${index}`}
                        className="vertical-separator"
                      />
                    )}
                    <div key={`${v}-${index}`} className="value">
                      {capitalize(v)}
                    </div>
                  </React.Fragment>
                )
              })
            : children}
        </div>
      </div>
      {displaySubtext && (
        <div className="item-card-subtext-wrapper">
          <div className="item-card-subtext">
            <div className="subtext">
              <div className="subtext-label">{subTextLabel}</div>
              <div className="value-block">
                <div key={'spacer'} className="spacer" />
                {subTextValueList
                  ? subTextValueList.map((v, index) => {
                      return (
                        <div key={`v-sep-${index}`} style={{ display: 'flex' }}>
                          {index === 0 ? null : (
                            <div className="vertical-separator" />
                          )}
                          <div key={`${v}-${index}`} className="value">
                            {v}
                          </div>
                        </div>
                      )
                    })
                  : children}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
