import React from 'react'
import { Modal, SubmitButton } from './../../components'
import './go-back-modal.css'
import goBackModalIcon from './../../assets/imgs/go-back-modal-icon.svg'

export const GoBackModal = ({
  handleCancel,
  handleGoBack,
}: {
  handleCancel: () => void
  handleGoBack: () => void
}) => {
  return (
    <Modal closeMethod={handleCancel}>
      <div className="go-back-modal-body">
        <img src={goBackModalIcon} />
        <div className="title typog-h3-med">Before you go back...</div>
        <div className="text typog-body">
          By going back to the previous screen, all your previous offers will be
          reopened. Would you like to go back?
        </div>
        <SubmitButton onClick={handleGoBack} width={270}>
          YES, GO BACK
        </SubmitButton>
        <div className="cancel-link typog-body" onClick={handleCancel}>
          Cancel
        </div>
      </div>
    </Modal>
  )
}
