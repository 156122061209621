import './page-loader.css'
import uploading from '../../assets/imgs/uploading.svg'

export const PageLoader = ({ display }: { display?: boolean }) => {
  return display ? (
    <div className="page-container">
      <div className="spinner-frame">
        <img
          className="rotate"
          src={uploading}
          alt={`loading icon`}
          width="100"
          height="100"
        />
      </div>
    </div>
  ) : (
    <></>
  )
}
