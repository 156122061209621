import * as React from 'react'
import './percentage-tool-tip.css'

export const PercentageToolTip = ({
  percent,
  display,
}: {
  percent: number
  display: boolean
}) => {
  return (
    <div className="percentage-tool-tip-wrapper">
      <div className={`${display ? 'tool-tip-style' : 'hidden'}`}>
        <p className="percentage-tool-tip-text">
          <span className="tool-tip-percent typog-body">{percent}%</span>
          <span className="percentage-tool-tip-text-iso typog-body-small-subtitle">
            Origination Fee Traded{' '}
          </span>
        </p>
      </div>
    </div>
  )
}
