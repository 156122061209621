import { Page } from '../../components'
import './about.css'
import * as packageInfo from '../../../package.json'

const { name, version } = packageInfo

export const About = () => {
  return (
    <Page title={'About Partner Portal'}>
      <div className="about-single-line">
        <label className="about-text" htmlFor="appName">
          {`Name:`}
        </label>
        <input
          className="about-input typog-body"
          type="text"
          readOnly={true}
          id="appName"
          value={name}
        />
      </div>
      <div className="about-single-line">
        <label className="about-text" htmlFor="appVersion">
          {`Version:  `}
        </label>
        <input
          className="about-input typog-body"
          type="text"
          readOnly={true}
          id="appVersion"
          value={version}
        />
      </div>
    </Page>
  )
}
