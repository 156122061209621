import { Modal, SubmitButton } from '..'
import './error-modal.css'
import iconError from '../../assets/imgs/icon-error.svg'

export const ErrorModal = ({
  isDisplay,
  handleClose,
  handleTryAgain,
}: {
  isDisplay: boolean
  handleClose: () => void
  handleTryAgain?: () => void
}) => {
  // isDisplay, controls modal show/hide
  if (!isDisplay) return null
  // The "TRY AGAIN" button will execute a handleTryAgain handler if it exists
  // If no handleTryAgain handler has been provided, it will execute the handleClose handler
  const tryAgainClickHandler = handleTryAgain ? handleTryAgain : handleClose
  return (
    <Modal closeMethod={handleClose}>
      <div className="error-modal-body">
        <img src={iconError} />
        <div className="error-modal-title typog-h3-med">Whoops!</div>
        <div className="error-modal-text typog-body">
          {`Sorry, looks like something went wrong.
          Please try completing the action again.`}
        </div>
        <SubmitButton onClick={tryAgainClickHandler} width={270}>
          TRY AGAIN
        </SubmitButton>
      </div>
    </Modal>
  )
}
