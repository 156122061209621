import './submit-button.css'

const pxString = (val: string | number) => {
  let strVal
  if (typeof val === 'number') {
    strVal = val + 'px'
  } else {
    if (!val.includes('px')) {
      strVal = val + 'px'
    }
  }
  return strVal
}

export const SubmitButton = ({
  onClick,
  width,
  paddingLR,
  children,
}: {
  onClick: () => void
  width?: number | string
  paddingLR?: number | string
  children: React.ReactNode
}) => {
  return (
    <div
      className="button-wrapper"
      style={{
        width: `${width ? pxString(width) : ''}`,
        paddingLeft: `${paddingLR ? pxString(paddingLR) : ''}`,
        paddingRight: `${paddingLR ? pxString(paddingLR) : ''}`,
      }}
      onClick={onClick}
    >
      {children || 'SUBMIT'}
    </div>
  )
}
