import './merchant-card.css'
import { CheckboxButton } from '../checkbox-button'
import {
  PaymentFrequency,
  PricingTier,
} from '@mulliganfunding/api-partner-portal'
import { RadioButtonGroup } from '../radio-button-group'
import { FormDropdown } from '../form-dropdown'

export const MerchantCard = ({
  loanSpecialist,
  merchantName,
  tier,
  interestForgiveness,
  tierList,
  interestForgivenessList,
  handleSelectedTierChange,
  handleInterestForgivenessChange,
  handlePaymentFrequencyChange,
  paymentFrequency,
  paymentFrequencyOptions,
}: {
  loanSpecialist: string
  merchantName: string
  tier: PricingTier | Record<string, never>
  interestForgiveness: string
  tierList: Array<string>
  interestForgivenessList: Array<string>
  paymentFrequency?: string
  paymentFrequencyOptions: PaymentFrequency[]
  handleSelectedTierChange: (newValue: string) => void
  handleInterestForgivenessChange: (newValue: string) => void
  handlePaymentFrequencyChange: (newValue: string) => void
}) => {
  return (
    <div className="merchant-card">
      <div className="merchant-block">
        <div className="line-margin-bottom">
          <div className="merchant-label typog-h4-small">{`Merchant:`}</div>
          <div className="merchant-value typog-h4-small">{merchantName}</div>
        </div>
        {tierList.length > 1 && (
          <div className="line-margin-bottom">
            <div className="merchant-label typog-h4-small">{`Tier:`}</div>
            <div className="merchant-tier-block">
              {tierList.map((tierName) => {
                return (
                  <CheckboxButton
                    key={tierName}
                    handleChange={handleSelectedTierChange}
                    isSelected={tier?.tier_basic_name
                      ?.toLocaleLowerCase()
                      .includes(tierName)}
                    id={tierName}
                    label={tierName}
                    name={'selectedTier'}
                    isLocked={tierList.length == 1}
                  />
                )
              })}
            </div>
          </div>
        )}

        <div className="line">
          <RadioButtonGroup
            title={'Interest Forgiveness'}
            value={interestForgiveness}
            name={'interestForgiveness'}
            optionsList={interestForgivenessList}
            handleOnChange={handleInterestForgivenessChange}
            symbol={'%'}
          />
          <FormDropdown
            title={'Payment Frequency'}
            value={paymentFrequency}
            name={'paymentFrequency'}
            optionsList={paymentFrequencyOptions}
            handleOnChange={handlePaymentFrequencyChange}
          />
        </div>
      </div>
      <div className="specialist-block">
        <div className="line">
          <div className="merchant-label typog-h4-small">{`Loan Specialist:`}</div>
          <div className="specialist-details">
            <div className="specialist-contact">
              <div className="specialist-name typog-body-large">
                {loanSpecialist}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
