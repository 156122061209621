export const FooterNoteNode = (
  <div>
    <div style={{ padding: '5px' }}>
      <sup>1</sup>This is a conditional offer. Mulligan Funding, as agent for
      FinWise Bank, reserves the right to require additional documentation for
      all applicants, including the borrowers' signed loan agreement, driver’s
      license, voided check, and bank verification.
    </div>

    <div style={{ padding: '5px' }}>
      Final loan decision may be subject to underwriting by FinWise Bank.
    </div>

    <div style={{ padding: '5px' }}>
      The majority of Mulligan Funding, LLC business loans are issued by FinWise
      Bank, a Utah state-chartered bank. Member FDIC.
    </div>
  </div>
)
