import './information-section.css'
import infoAlertLinkIcon from '../../assets/imgs/info-alert-link-icon.svg'

export const InformationSection = ({ text }: { text: string }) => {
  return (
    <div className="information-section-info">
      <img
        className="information-section-info-icon"
        src={infoAlertLinkIcon}
        alt={`info icon`}
      />
      <div className="typog-body-small">{text}</div>
    </div>
  )
}
