import { type ReactNode } from 'react'
import './card.css'

export const Card = ({
  children,
  title,
  maxWidth,
  width,
}: {
  title?: string
  maxWidth?: string
  width?: string
  children: ReactNode
}) => {
  const addMaxWidth = maxWidth ? ` max-width: ${maxWidth}` : ''
  const addWidth = width ? ` width: ${width}` : ''

  return (
    <div
      className="generic-card"
      style={{ maxWidth: `${addMaxWidth}`, width: `${addWidth}` }}
    >
      {!title ? null : <div className="generic-card-title">{title}</div>}
      {children}
    </div>
  )
}
