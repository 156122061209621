import * as Sentry from '@sentry/react'
import { FallbackRender } from '@sentry/react'
import React, { type ReactElement, type ReactNode } from 'react'

export const SentryComponent = ({
  children,
  fallback,
}: {
  fallback?: ReactElement | FallbackRender
  children?: ReactNode
}) => {
  return (
    <>
      <Sentry.ErrorBoundary
        fallback={fallback ? fallback : <p>An error has occurred</p>}
      >
        {children}
      </Sentry.ErrorBoundary>
    </>
  )
}
