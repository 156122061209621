import './offer-selected.css'
import {
  Card,
  ErrorModal,
  FooterNoteNode,
  Page,
  SubmitButton,
} from './../../components'
import {
  acceptedOfferPostState,
  isPageLoadingState,
  loadSavedCalcState,
  pendingOfferState,
  requirementListState,
} from './../../state'
import {
  format$,
  formatValue,
  logColor,
  roundToNearestTenThousandth,
  scrollToTop,
} from './../../utils'
import { DEFAULT_HEADERS } from '@mulliganfunding/api-partner-portal'
import {
  PendingOfferStatus,
  POST_HEADERS_JSON,
  type PendingOfferTemp,
} from './../../constants'
import { useEffect, useState } from 'react'
import { GoBackModal } from './go-back-modal'
import iconInfo from '../../assets/imgs/icon-info.svg'
import iconLeftChevron from '../../assets/imgs/left-chevron.svg'
import iconOfferSelected72x72 from '../../assets/imgs/offer-selected-72x72.svg'
import { ToolTip } from './../../components/tool-tip'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'

const OfferDetailsLineItem = ({
  label,
  value,
}: {
  label: string
  value: string
}) => {
  return (
    <div className="offer-details-line-item">
      <div className="offer-details-label typog-tagline-small">
        {label.toUpperCase()}
      </div>
      <div className="offer-details-value typog-body">{value}</div>
    </div>
  )
}

export const OfferSelectedPage = () => {
  const setIsLoading = useSetAtom(isPageLoadingState)

  const [isDisplayTooltip, setIsDisplayTooltip] = useState<boolean>(false)
  const [pendingOffer, setPendingOffer] = useAtom(pendingOfferState)
  const [isDisplayErrorModal, setIsDisplayErrorModal] = useState<boolean>(false)
  const acceptedOffer = useAtomValue(acceptedOfferPostState)
  const [isDisplayGoBackModal, setIsDisplayGoBackModal] =
    useState<boolean>(false)
  const requirementList = useAtomValue(requirementListState)
  const setLoadSavedCalc = useSetAtom(loadSavedCalcState)

  logColor.gray('offer selected: ', { acceptedOffer, requirementList })

  const handleGoBackModalLaunch = () => setIsDisplayGoBackModal(true)

  const handleGoBack = async () => {
    setIsLoading(true)
    // TODO modal to warn / confirm go back
    const response = await fetch(`/api/partner-portal/calculator/status`, {
      method: 'POST',
      headers: DEFAULT_HEADERS,
      body: JSON.stringify({ status: 'ACTIVE', uuid: pendingOffer.uuid }),
    })
    if (response.ok) {
      const solidPendingOffer = pendingOffer as PendingOfferTemp
      // go back, set status to active, accepted
      setPendingOffer({ ...solidPendingOffer, status: 'ACTIVE' })
      setLoadSavedCalc(true)
    } else {
      // error response
      logColor.red(
        'ERROR offer selecte go back attempt returned an error',
        response,
      )
      setIsDisplayGoBackModal(false)
      setIsDisplayErrorModal(true)
    }
    setIsLoading(false)
  }

  const handleCreateLoanClick = async () => {
    setIsLoading(true)
    const solidPendingOffer = pendingOffer as PendingOfferTemp
    // TODO add try / catch
    const status =
      PendingOfferStatus[
        PendingOfferStatus.ACCEPTED_SELECT_DOC_DELIVERY_PREFERENCE
      ]
    const response = await fetch(`/api/partner-portal/calculator/status`, {
      method: 'POST',
      headers: POST_HEADERS_JSON,
      body: JSON.stringify({
        status,
        uuid: solidPendingOffer.uuid,
      }),
    })
    if (response.ok) {
      // go back, set status to active, accepted
      setPendingOffer({ ...solidPendingOffer, status })
    } else {
      setIsDisplayErrorModal(true)
      // error response
      logColor.red('ERROR Offer Selected > Create click returned an error', {
        status,
        response,
      })
    }
    setIsLoading(false)
  }

  const modalCloseMethod = () => setIsDisplayGoBackModal(false)

  const ao = acceptedOffer
  const pt = acceptedOffer?.pricing_tier
  const round4 = roundToNearestTenThousandth

  useEffect(() => {
    scrollToTop()
  }, [])

  // display origination fee text/icon/tooltip if not empty/0
  let originationFeeTradedDisplay = null
  if (ao?.points_traded) {
    originationFeeTradedDisplay = (
      <>
        {' '}
        | {ao?.points_traded}%{` Origination Fee Traded `}
        <div
          className="icon-tooltip-wrapper"
          onMouseOver={() => setIsDisplayTooltip(true)}
          onMouseOut={() => setIsDisplayTooltip(false)}
        >
          <img className="icon-info" src={iconInfo} />
          <ToolTip display={isDisplayTooltip}>
            Trading origination points results in an adjusted commision total
            and buy rate.
          </ToolTip>
        </div>
      </>
    )
  }

  return (
    <>
      {isDisplayGoBackModal && (
        <GoBackModal
          handleCancel={modalCloseMethod}
          handleGoBack={handleGoBack}
        />
      )}
      <ErrorModal
        isDisplay={isDisplayErrorModal}
        handleClose={() => setIsDisplayErrorModal(false)}
      />
      <Page
        title={'Offer Submission Calculator'}
        footerNoteNode={FooterNoteNode}
      >
        <div
          className="offer-selected-go-back-section"
          onClick={handleGoBackModalLaunch}
        >
          <img
            className="go-back-chevron-icon"
            src={iconLeftChevron}
            alt={'go back icon'}
          />
          <div className="go-back-link"> Back To Selected Offer</div>
        </div>
        <Card width="1140px">
          <div className="offer-selected-body-content-wrapper">
            <div className="offer-selected">
              <img src={iconOfferSelected72x72} alt={`offer selected icon`} />
              <div className="offer-title typog-h1-extra-large">
                Offer Selected
              </div>
              <div className="offer-text typog-body">
                You have selected an offer for{' '}
                <span className="semi-bold">{pendingOffer?.merchant_name}</span>
                .<br />
                Review the offer details below and proceed to create the loan
                agreement.
              </div>
            </div>
            <div className="shaded-block-repayment">
              <div className="offer-details-white-block">
                <div className="total-repayment typog-tagline-small">
                  TOTAL REPAYMENT
                </div>
                <div className="total-repayment-amount">
                  {format$(acceptedOffer?.total_repayment)}
                </div>
                <div className="repayment-details typog-body">
                  {round4(ao?.buy_rate)} Buy Rate | {round4(ao?.factor)} Factor
                </div>
                <div className="repayment-details typog-body">
                  {pt?.early_payoff_int_forgiveness}% Interest Forgiveness
                  {originationFeeTradedDisplay}
                </div>
              </div>
              <div className="offer-details-tri-column">
                <div className="offer-details-column">
                  <OfferDetailsLineItem
                    label="LOAN AMOUNT"
                    value={format$(ao?.funding_amt)}
                  />
                  <OfferDetailsLineItem
                    label="NET FUNDING AMOUNT"
                    value={format$(ao?.loan_amt_net_origin)}
                  />
                </div>
                <div className="offer-details-column">
                  <OfferDetailsLineItem
                    label="TERM"
                    value={ao?.term_months + ' months'}
                  />
                  <OfferDetailsLineItem
                    label="COMMISSION"
                    value={`${formatValue(
                      ao?.commission_percentage * 100,
                      '%',
                    )} | ${format$(ao?.commission_dollar_amt)}`}
                  />
                </div>
                <div className="offer-details-column">
                  <OfferDetailsLineItem
                    label="PAYMENT"
                    value={`${format$(acceptedOffer?.payment, 2)} ${
                      ao?.frequency
                    }`}
                  />
                  <OfferDetailsLineItem
                    label="ORIGINATION FEE"
                    value={`${formatValue(
                      acceptedOffer?.orig_fee_percent,
                      '%',
                    )} | ${format$(ao?.orig_fee_dollars)}`}
                  />
                </div>
              </div>
            </div>
            <div className="shaded-block-req-to-fund">
              <div className="req-to-fund-title typog-body">
                Requirements to Fund (
                {Array.isArray(requirementList) ? requirementList.length : 0})
              </div>
              {Array.isArray(requirementList) &&
                requirementList.map((req, idx) => (
                  <div
                    className="req-to-fund-req-line"
                    key={`req-to-fund-line-${idx}`}
                  >
                    <span className="bullet">●</span> {req}
                  </div>
                ))}
              <div className="req-note-1">
                Additional documentation could be required<sup>1</sup>
              </div>
            </div>
            <SubmitButton paddingLR={35} onClick={handleCreateLoanClick}>
              Continue
            </SubmitButton>
          </div>
        </Card>
      </Page>
    </>
  )
}
