import { InputMode } from './../../constants'
import { useEffect, useState } from 'react'
import './input-display-box.css'
import { isEmailValid } from './../../utils'

export const InputOrDisplayBox = ({
  value,
  mode,
  onChange,
  isSubmitPressed,
}: {
  value: string
  onChange?: (e) => void
  mode: InputMode
  isSubmitPressed: boolean
}) => {
  const [isEmailBlurred, setIsEmailBlurred] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    if (isSubmitPressed || isEmailBlurred) {
      const newIsError = !isEmailValid(value)
      if (isError !== newIsError) {
        setIsError(newIsError)
      }
    }
  }, [isEmailBlurred, isSubmitPressed, value])

  if (mode === InputMode.display) {
    return <div className="display-box">{value}</div>
  }
  if (mode === InputMode.display_bold) {
    return <div className="display-bold-box">{value}</div>
  }
  if (mode === InputMode.input) {
    return (
      <div className="input-display-box-error-wrapper">
        <input
          className={isError ? 'text-input-error' : 'text-input'}
          type={'text'}
          value={value}
          onChange={onChange}
          onBlur={() => setIsEmailBlurred(true)}
        />
        <div
          className={
            isError ? 'input-error-message typog-body-small' : 'hide-me'
          }
        >
          Please enter an email address
        </div>
      </div>
    )
  }
}
